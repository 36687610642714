import { useBaseQuery } from "../base-query";
import adminService from "../../services/admin-service";
import { TSite, TCreateSite } from "../../../types/entity-manager";
import { useBaseMutation } from "../base-mutation";
import { QueryClient } from "@tanstack/react-query";

export const useSites = () =>
  useBaseQuery<null, TSite[]>(["get-sites"], () => adminService.getSites());

export const useCreateSite = (queryClient: QueryClient) =>
  useBaseMutation<TCreateSite, TSite>(
    (payload: TCreateSite) => adminService.addSite(payload),
    {
      403: "Недостаточно прав",
      404: "Сайт не найден",
      409: "Сайт с таким названием или ID уже существует",
      422: "Ошибка валидации - проверьте правильность заполнения полей",
      500: "Не удалось совершить действие",
    },
    () => queryClient.invalidateQueries({ queryKey: ["get-sites"] })
  );

export const useUpdateSite = (queryClient: QueryClient) =>
  useBaseMutation<TSite, TSite>(
    (payload: TSite) => adminService.updateSite(payload),
    {
      403: "Недостаточно прав",
      404: "Сайт не найден",
      409: "Сайт с таким названием или ID уже существует",
      422: "Ошибка валидации - проверьте правильность заполнения полей",
      500: "Не удалось совершить действие",
    },
    () => queryClient.invalidateQueries({ queryKey: ["get-sites"] })
  );

export const useDeleteSite = (queryClient: QueryClient) =>
  useBaseMutation<number, TSite>(
    (site_id: number) => adminService.deleteSite(site_id),
    {},
    () => queryClient.invalidateQueries({ queryKey: ["get-sites"] })
  );
