// currencies
export const ENDPOINT_CURRENCY = "pair_builder/v1/currency";

// cities
export const ENDPOINT_CITY = "pair_builder/v1/city";

// countries
export const ENDPOINT_COUNTRY = "pair_builder/v1/country";

//site
export const ENDPOINT_SITE = "pair_builder/v1/site";
