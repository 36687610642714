import { Tabs, TabsContent, TabsList, TabsTrigger } from "@radix-ui/react-tabs";
import { useView, ViewEnum } from "../../shared/store/entity-store";
import CardMenu from "../../shared/ui/CardMenu";
import CityList from "./components/CityList";
import CountryList from "./components/CountryList";
import CurrencyList from "./components/CurrencyList";
import SiteList from "./components/SiteList";

const tabMenu = [
  { title: "Города", code: ViewEnum.city, componentList: <CityList /> },
  { title: "Валюты", code: ViewEnum.currency, componentList: <CurrencyList /> },
  { title: "Страны", code: ViewEnum.country, componentList: <CountryList /> },
  { title: "Обменники", code: ViewEnum.site, componentList: <SiteList /> },
];

const EntityManager = () => {
  const { view, setView } = useView();

  return (
    <Tabs
      defaultValue={"city"}
      value={view}
      className="flex flex-col gap-4 px-4 py-10 ">
      <TabsList className="flex gap-2 self-center">
        {tabMenu.map((item) => (
          <TabsTrigger
            key={item.code}
            value={item.code}
            onClick={() => setView(item.code)}>
            <CardMenu
              title={item.title}
              active={view === item.code}
            />
          </TabsTrigger>
        ))}
      </TabsList>
      {tabMenu.map((item) => (
        <TabsContent value={item.code}>{item.componentList}</TabsContent>
      ))}
    </Tabs>
  );
};

export default EntityManager;
