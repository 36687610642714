import { z } from "zod";

export const countryCreateSchema = z.object({
  country_name: z.string().min(1, {
    message: "Обязательное поле",
  }),
  country_code: z.string().min(1, {
    message: "Обязательное поле",
  }),
});

export const countryDefaultValues = {
  country_name: "",
  country_code: "",
};
