import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "./components/ui/toaster";
import { useState } from "react";
import Routing from "./app/route/routing";
import Header from "./widgets/header";
import { useAuthStore } from "./shared/store/auth-store";

function App() {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: 1,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
          },
        },
      })
  );

  const { me } = useAuthStore();

  return (
    <QueryClientProvider client={queryClient}>
      <div className={`flex flex-col h-full min-h-[100vh]`}>
        {me && <Header />}
        <Routing />
        <Toaster />
      </div>
    </QueryClientProvider>
  );
}

export default App;
