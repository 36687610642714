import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

export function useBaseMutationWithoutPayload<R>(
  request: () => Promise<AxiosResponse<R, string>>,
  onSuccess?: () => void
) {
  const { mutateAsync, isPending, error } = useMutation({
    mutationFn: async () => {
      try {
        const data = await request();
        if (!data) {
          throw new Error("500");
        }
        if (data.status < 200 || data.status >= 300) {
          throw new Error(data.status?.toString());
        }
        return data.data;
      } catch (e) {
        throw new Error(e as string);
      }
    },
    onSuccess,
  });

  return {
    mutateAsync,
    isPending,
    error,
  };
}
