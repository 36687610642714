import { useBaseQuery } from "../base-query";
import adminService from "../../services/admin-service";
import { TCountry, TCreateCountry } from "../../../types/entity-manager";
import { useBaseMutation } from "../base-mutation";
import { QueryClient } from "@tanstack/react-query";

export const useCountries = () =>
  useBaseQuery<null, TCountry[]>(["get-countries"], () => adminService.getCountries());

export const useCreateCountry = (queryClient: QueryClient) =>
  useBaseMutation<TCreateCountry, TCountry>(
    (payload: TCreateCountry) => adminService.addCountry(payload),
    {
      403: "Недостаточно прав",
      404: "Страна не найдена",
      409: "Страна с таким названием или ID уже существует",
      422: "Ошибка валидации - проверьте правильность заполнения полей",
      500: "Не удалось совершить действие",
    },
    () => queryClient.invalidateQueries({ queryKey: ["get-countries"] })
  );

export const useUpdateCountry = (queryClient: QueryClient) =>
  useBaseMutation<TCountry, TCountry>(
    (payload: TCountry) => adminService.updateCountry(payload),
    {
      403: "Недостаточно прав",
      404: "Страна не найдена",
      409: "Страна с таким названием или ID уже существует",
      422: "Ошибка валидации - проверьте правильность заполнения полей",
      500: "Не удалось совершить действие",
    },
    () => queryClient.invalidateQueries({ queryKey: ["get-countries"] })
  );

export const useDeleteCountry = (queryClient: QueryClient) =>
  useBaseMutation<string, TCountry>(
    (country_code: string) => adminService.deleteCountry(country_code),
    {},
    () => queryClient.invalidateQueries({ queryKey: ["get-countries"] })
  );
