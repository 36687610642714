import UserAvatar from "./UserAvatar";

const Header = () => {
  return (
    <header className="flex justify-end items-center bg-[rgb(40,44,52)] shadow-xl text-white px-[5%] py-2.5">
      <UserAvatar />
    </header>
  );
};

export default Header;
