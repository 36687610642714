import { useQuery } from "@tanstack/react-query";
import { toast } from "../../../hooks/use-toast";
import { NestedObjects } from "../../types/entity-manager";
import { AxiosResponse } from "axios";

export function useBaseQuery<P, R>(
  queryKeys: string[],
  request: (payload?: P) => Promise<AxiosResponse<R, string>>,
  enabled: boolean = true,
  errors?: NestedObjects<string>
) {
  const { data, isPending, error } = useQuery({
    queryKey: queryKeys,
    queryFn: async () => {
      try {
        if (queryKeys?.some((el) => el === "")) {
          return null;
        }
        const response = await request();
        if (!response) {
          throw new Error("500");
        }
        if (response.status < 200 || response.status >= 300) {
          if (errors) {
            const baseToast = {
              variant: "destructive" as const,
            };
            Object.entries(errors).forEach(([statusCode, message]) => {
              if (response.status === +statusCode) {
                toast({ ...baseToast, description: message as string });
                throw new Error(response.status?.toString());
              }
            });
            toast({ ...baseToast, description: "Неизвестная ошибка" });
          }
          throw new Error(response.status?.toString());
        }

        return response.data;
      } catch (e) {
        toast({
          variant: "destructive",
          description: e instanceof Error ? e.message : "Произошла непредвиденная ошибка.",
        });
        throw new Error(e as string);
      }
    },
    enabled: enabled,
  });

  return {
    data,
    isPending,
    error,
  };
}
