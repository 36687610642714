import { ChevronDown, LogOut } from "lucide-react";
import { Button } from "../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import { Avatar, AvatarFallback, AvatarImage } from "../../components/ui/avatar";
import { useAuthStore } from "../../shared/store/auth-store";
import { useLogout } from "../../shared/api/query/use-auth.ts/use-auth";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../app/route/routes";

const UserAvatar = () => {
  const navigate = useNavigate();

  const { me, setMe } = useAuthStore();
  const { mutateAsync: logout } = useLogout();

  const onLogoutClickHandler = async () => {
    try {
      await logout();
    } finally {
      setMe(null);
      navigate(ROUTES.AUTH, { replace: true });
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        asChild
        className="hover:text-white">
        <Button
          variant="ghost"
          className="h-auto p-0 hover:bg-transparent">
          <Avatar className="text-black">
            <AvatarImage />
            <AvatarFallback>{me?.user_name[0].toLocaleUpperCase()}</AvatarFallback>
          </Avatar>
          <ChevronDown
            size={16}
            strokeWidth={2}
            className="ms-2 opacity-60"
            aria-hidden="true"
          />
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent className="max-w-64">
        <DropdownMenuLabel className="flex min-w-0 flex-col">
          <span className="truncate text-sm font-medium text-foreground">{me?.user_name}</span>
          <span className="truncate text-xs font-normal text-muted-foreground">
            {me?.user_role_name}
          </span>
        </DropdownMenuLabel>

        <DropdownMenuSeparator />

        <DropdownMenuItem
          className="flex items-center gap-2"
          onClick={onLogoutClickHandler}>
          <LogOut
            size={16}
            strokeWidth={2}
            className="opacity-60"
            aria-hidden="true"
          />
          <span>Выйти</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default UserAvatar;
