import { AxiosResponse } from "axios";
import { BASE_URL } from "../../constants";

import { TLogin } from "../../types/auth";
import { TUser } from "../../types/types";
import api, { tokenRefreshClient } from "../api-instance";

export default class authService {
  static async login(data: TLogin): Promise<AxiosResponse<TUser>> {
    return api.post(`${BASE_URL}/auth/login/`, data);
  }
  static async logout() {
    return api.post(`${BASE_URL}/auth/logout/`);
  }

  static async refresh() {
    return tokenRefreshClient.patch(`${BASE_URL}/auth/refresh/`);
  }
}
