import { useCallback, useState } from "react";
import CardListItem from "../../../../shared/ui/CardListItem";
import ListWrapper from "../../../../shared/ui/ListWrapper";
import CreateModal from "./CreateModal";
import { TSite } from "../../../../shared/types/entity-manager";

import { useQueryClient } from "@tanstack/react-query";
import {
  useCreateSite,
  useUpdateSite,
  useDeleteSite,
  useSites,
} from "../../../../shared/api/query/use-admin/use-sites";

const SiteList = () => {
  const queryClient = useQueryClient();
  const { data: sites } = useSites();

  const { mutateAsync: createSite } = useCreateSite(queryClient);
  const { mutateAsync: updateSite } = useUpdateSite(queryClient);
  const { mutateAsync: deleteSite } = useDeleteSite(queryClient);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editSite, setEditSite] = useState<TSite | undefined>(undefined);

  const onChangeOpenModalHandler = useCallback(() => {
    if (isEditModalOpen) setEditSite(undefined);
    setIsEditModalOpen((open) => !open);
  }, [isEditModalOpen]);

  const onClickEditSiteHandler = (site: TSite) => {
    setEditSite(site);
    onChangeOpenModalHandler();
  };

  return (
    <ListWrapper>
      <CreateModal
        open={isEditModalOpen}
        onChangeOpen={onChangeOpenModalHandler}
        site={editSite}
        onEdit={(payload) => updateSite(payload)}
        onCreate={(payload) => createSite(payload)}
      />

      {sites?.length ? (
        sites.map((item) => (
          <CardListItem
            key={item.site_id}
            title={item.site_name}
            onClickEdit={() => onClickEditSiteHandler(item)}
            onClickDelete={() => deleteSite(item.site_id)}
          />
        ))
      ) : (
        <CardListItem title="Список обменников пуст" />
      )}
    </ListWrapper>
  );
};

export default SiteList;
