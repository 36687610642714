import { AxiosResponse } from "axios";
import { BASE_URL } from "../../constants";
import {
  TCurrency,
  TCreateCurrency,
  TCreateCity,
  TCity,
  TCountry,
  TCreateCountry,
  TCreateSite,
  TSite,
} from "../../types/entity-manager";
import api from "../api-instance";
import * as T from "./types";
import { ENDPOINT_CITY, ENDPOINT_COUNTRY, ENDPOINT_CURRENCY, ENDPOINT_SITE } from "./endpoints";
import { TUser } from "../../types/types";

export default class adminService {
  // currencies
  static async getCurrencies(): T.GetPromiseCurrencies {
    return api.get(`${BASE_URL}/${ENDPOINT_CURRENCY}/`);
  }
  static async addCurrency(payload: TCreateCurrency): T.PromiseCurrency {
    return api.post(`${BASE_URL}/${ENDPOINT_CURRENCY}/`, payload);
  }
  static async updateCurrency(payload: TCurrency): T.PromiseCurrency {
    return api.patch(`${BASE_URL}/${ENDPOINT_CURRENCY}/`, payload);
  }
  static async deleteCurrency(currency_code: string): T.PromiseCurrency {
    return api.delete(`${BASE_URL}/${ENDPOINT_CURRENCY}/?currency_code=${currency_code}`);
  }

  // cities
  static async getCities(): T.GetPromiseCities {
    return api.get(`${BASE_URL}/${ENDPOINT_CITY}/`);
  }
  static async addCity(payload: TCreateCity): T.PromiseCity {
    return api.post(`${BASE_URL}/${ENDPOINT_CITY}/`, payload);
  }
  static async updateCity(payload: TCity): T.PromiseCity {
    return api.patch(`${BASE_URL}/${ENDPOINT_CITY}/`, payload);
  }
  static async deleteCity(city_id: string): T.PromiseCity {
    return api.delete(`${BASE_URL}/${ENDPOINT_CITY}/${city_id}`);
  }

  // countries
  static async getCountries(): T.GetPromiseCountries {
    return api.get(`${BASE_URL}/${ENDPOINT_COUNTRY}/`);
  }
  static async addCountry(payload: TCreateCountry): T.PromiseCountry {
    return api.post(`${BASE_URL}/${ENDPOINT_COUNTRY}/`, payload);
  }
  static async updateCountry(payload: TCountry): T.PromiseCountry {
    return api.patch(`${BASE_URL}/${ENDPOINT_COUNTRY}/`, payload);
  }
  static async deleteCountry(country_code: string): T.PromiseCountry {
    return api.delete(`${BASE_URL}/${ENDPOINT_COUNTRY}/`, { data: { country_code: country_code } });
  }

  // site
  static async getSites(): T.GetPromiseSites {
    return api.get(`${BASE_URL}/${ENDPOINT_SITE}/`);
  }
  static async addSite(payload: TCreateSite): T.PromiseSite {
    return api.post(`${BASE_URL}/${ENDPOINT_SITE}/`, payload);
  }
  static async updateSite(payload: TSite): T.PromiseSite {
    return api.patch(`${BASE_URL}/${ENDPOINT_SITE}/`, payload);
  }
  static async deleteSite(site_id: number): T.PromiseSite {
    return api.delete(`${BASE_URL}/${ENDPOINT_SITE}/${site_id}`);
  }

  // users
  static async me(): Promise<AxiosResponse<TUser>> {
    return api.get(`${BASE_URL}/user/me`);
  }
}
