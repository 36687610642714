import { FC, useState } from "react";
import { Trash, Pencil } from "lucide-react";
import { Card } from "../../../components/ui/card";
import { Button } from "../../../components/ui/button";
import DeleteAlert from "./DeleteAlert";

interface CardListItemProps {
  title: string;
  description?: string;
  prefixIcon?: string;

  onClickDelete?: () => void;
  onClickEdit?: () => void;
}

const CardListItem: FC<CardListItemProps> = ({
  title,
  description,
  onClickDelete,
  onClickEdit,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Card className="py-3 px-4 flex gap-2">
      <div className="w-full content-center">{title}</div>
      <div className="content-center text-xs">{description}</div>

      {onClickEdit && (
        <Button
          onClick={onClickEdit}
          variant="ghost"
          size="icon">
          <Pencil height="20" />
        </Button>
      )}

      {onClickDelete && (
        <Button
          onClick={() => setIsModalOpen(true)}
          variant="ghost"
          size="icon">
          <Trash height="20" />
        </Button>
      )}

      <DeleteAlert
        open={isModalOpen}
        onOpenChange={() => setIsModalOpen((open) => !open)}
        onDelete={() => onClickDelete?.()}
      />
    </Card>
  );
};

export default CardListItem;
