import { useMutation } from "@tanstack/react-query";
import { toast } from "../../../hooks/use-toast";
import { NestedObjects } from "../../types/entity-manager";
import { AxiosResponse } from "axios";

export function useBaseMutation<P, R>(
  request: (payload: P) => Promise<AxiosResponse<R, string>>,
  errors?: NestedObjects<string>,
  onSuccess?: () => void
) {
  const { mutateAsync, isPending, error } = useMutation({
    mutationFn: async (payload: P) => {
      try {
        const data = await request(payload);
        if (!data) {
          return null;
        }
        if (data.status < 200 || data.status >= 300) {
          if (errors) {
            const baseToast = {
              variant: "destructive" as const,
            };
            Object.entries(errors)?.forEach((error_el) => {
              if (data.status === +error_el[0]) {
                toast({ ...baseToast, description: error_el[1] as string });
                throw new Error(data.status?.toString());
              }
            });
            toast({ ...baseToast, description: "Неизвестная ошибка" });
          }
          throw new Error(data.status?.toString());
        }
        if (data.status == 201) {
          toast({
            variant: "default",
            description: "Успешно создано",
          });
        }
        if (data.status == 200) {
          toast({
            description: "Успешно обновлено",
          });
        }
        return data.data;
      } catch (e) {
        throw new Error(e as string);
      }
    },
    onSuccess: onSuccess,
  });

  return {
    mutateAsync,
    isPending,
    error,
  };
}
