export interface TCity {
  city_id: string;
  city_name: string;
  city_code: string;
  city_is_region: boolean;
  country_code: string;
  city_bestchange_id?: number;
}

export type TCreateCity = Omit<TCity, "city_id">;

export interface TCountry {
  country_id: number;
  country_name: string;
  country_code: string;
}

export type TCreateCountry = Omit<TCountry, "country_id">;

export enum CurrencyTypeEnum {
  crypto = "CRYPTO",
  cash = "CASH",
  card = "CARD",
}

export interface CurrencyCountry {
  country_name: string;
  country_code: string;
}

export interface TCurrency {
  currency_id: string;
  currency_name: string;
  currency_code: string;
  currency_type: CurrencyTypeEnum;
  currency_exnode_id: number;
  currency_bestchange_id: number;
  currency_bestchange_url: string;
  currency_base_code: string;
  countries?: CurrencyCountry[];
}

export type TCreateCurrency = Omit<TCurrency, "currency_id" | "currency_base_code" | "countries">;

export enum AggregatorEnum {
  bestchange = "Bestchange",
  exnode = "Exnode",
}

export interface TSite {
  site_id: number;
  site_name: string;
  site_url: string;
  site_db_schema: string;
  site_scam_checker_enabled: boolean;
  site_bestchange_id: number;
  site_exnode_id: number;
  site_aggregator_name: AggregatorEnum;
  site_update_frequency: number;
}

export type TCreateSite = Omit<TSite, "site_id">;

export type NestedObjects<T> = {
  [key: string]: T;
};
