import { Route, Routes, Outlet } from "react-router-dom";
import { ROUTES } from "./routes";
import EntityManager from "../../pages/entity-manager";
import { Auth } from "../../pages/auth";
import { useAuthStore } from "../../shared/store/auth-store";

const Routing = () => {
  const { me } = useAuthStore();

  return (
    <Routes>
      <Route
        path={ROUTES.AUTH}
        element={<Auth />}
      />
      {me && (
        <Route element={<Layout />}>
          <Route
            path={ROUTES.DEFAULT}
            element={<EntityManager />}
          />
        </Route>
      )}

    </Routes>
  );
};

const Layout = () => {
  return <Outlet />;
};

export default Routing;
