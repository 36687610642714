import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAuthStore } from "../../shared/store/auth-store";
import { authSchema } from "./validation";
import { Button } from "../../components/ui/button";
import { Card } from "../../components/ui/card";
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  Form,
} from "../../components/ui/form";
import { useLogin } from "../../shared/api/query/use-auth.ts/use-auth";
import { ROUTES } from "../../app/route/routes";
import { Input } from "../../components/ui/input";
import { InputOTP, InputOTPGroup, InputOTPSlot } from "../../components/ui/input-otp";
import { InputPassword } from "../../components/ui/show-hide-password-input";

export const Auth = () => {
  const navigate = useNavigate();

  const authForm = useForm<z.infer<typeof authSchema>>({
    resolver: zodResolver(authSchema),
  });

  const { me, setMe } = useAuthStore();
  const { mutateAsync: login, isPending } = useLogin();

  const onSubmit = async (values: z.infer<typeof authSchema>) => {
    const data = await login(values);

    if (data) setMe(data);
  };

  useEffect(() => {
    if (me) {
      navigate(ROUTES.DEFAULT);
    }
  }, [me]);

  return (
    <div className="flex w-full h-full min-h-[80vh] items-center justify-center">
      <Card className="p-4 min-w-60">
        <Form {...authForm}>
          <form
            onSubmit={authForm.handleSubmit(onSubmit)}
            className="w-full space-y-6">
            <div className="w-full space-y-2">
              <FormField
                control={authForm.control}
                name="user_name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Логин</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={authForm.control}
                name="user_password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Пароль</FormLabel>
                    <FormControl>
                      <InputPassword {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={authForm.control}
                name="verify_code"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>OTP</FormLabel>
                    <FormControl>
                      <InputOTP
                        maxLength={6}
                        {...field}>
                        <InputOTPGroup>
                          <InputOTPSlot index={0} />
                          <InputOTPSlot index={1} />
                          <InputOTPSlot index={2} />
                          <InputOTPSlot index={3} />
                          <InputOTPSlot index={4} />
                          <InputOTPSlot index={5} />
                        </InputOTPGroup>
                      </InputOTP>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <Button
              type="submit"
              disabled={isPending}>
              Войти
            </Button>
          </form>
        </Form>
      </Card>
    </div>
  );
};
